import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as IconArrowForward } from '../../assets/images/icon-arrow-forward.svg';
import envConfig, { WidgetOrigin } from '../../constants';

export interface RoundNextButtonProps {
  onClick: React.MouseEventHandler<HTMLSpanElement>;
  disabled?: boolean;
}

export const RoundNextButton: React.FC<RoundNextButtonProps> = function (props) {
  const { onClick, disabled = false } = props;
  const { WIDGET_ORIGIN } = envConfig;
  const isAquaOrigin = WIDGET_ORIGIN === WidgetOrigin.aquadental;

  return (
    <StyledRoundNextButton isAquaOrigin={isAquaOrigin} onClick={onClick} disabled={disabled}>
      <IconArrowForward />
    </StyledRoundNextButton>
  );
};

const StyledRoundNextButton = styled.span<{ isAquaOrigin: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
  background: ${(props) => props.theme.color.accentColor};
  color: ${(props) => props.theme.color.background};
  fill: ${(props) => !props.isAquaOrigin && props.theme.color.background};

  ${(props) => props.disabled
    && css`
      pointer-events: none;
      opacity: 0.65;
    `}
`;
