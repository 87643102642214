import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { theme } from '../../theme';

export interface CloseButtonProps {
  onClick?: Function;
}

export const CloseButton: React.FC<CloseButtonProps> = function (props) {
  const { onClick } = props;

  const handleClickButton = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <CloseButtonStyle type="button" onClick={handleClickButton}>
      <CloseIcon width={20} height={20} fill={theme.color.textColor} />
    </CloseButtonStyle>
  );
};

CloseButton.defaultProps = {
  onClick: () => {},
};

const CloseButtonStyle = styled.button`
  margin-right: -5px;
  margin-left: 5px;
  background-color: ${(props) => props.theme.color.background};
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => css`
    &:hover {
      cursor: pointer
    }
  `};
`;
