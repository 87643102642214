import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AquaDental } from '../../assets/images/logos/aqua-dental.svg';
import { ReactComponent as TandlakareLogo } from '../../assets/images/logos/tandlakare-logo.svg';
import envConfig, { WidgetOrigin } from '../../constants';

export const Header: React.FC = function () {
  const { WIDGET_ORIGIN } = envConfig;

  return (
    <HeaderWrapper>
      {WIDGET_ORIGIN === WidgetOrigin.aquadental ? <AquaDental /> : <TandlakareLogo />}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  min-width: 100%;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.background};
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.color.disabledColor};

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 50px;
    margin-bottom: 24px;
  }
`;
