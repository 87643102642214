export const closeAppByMessage = () => {
  if (window && window.parent) {
    window.parent.postMessage(
      {
        message: 'close',
      },
      '*',
    );
  }
};
