import envConfig, { WidgetOrigin } from './constants';
import { ReviewConfig } from './types/Models';

const widgetConfig: ReviewConfig = window.name ? JSON.parse(window.name) : {};
const { WIDGET_ORIGIN } = envConfig;
const isAquaOrigin = WIDGET_ORIGIN === WidgetOrigin.aquadental;

export const color = {
  background: widgetConfig.backgroundColor || '#ffffff',
  black: isAquaOrigin ? '#2B354D' : '#ffff',
  accentColor: widgetConfig.accentColor || isAquaOrigin ? '#f5cb53' : '#583091',
  textColor: widgetConfig.textColor || '#2b354d',
  disabledColor: widgetConfig.disabledColor || '#cbcbcb',
  additional: '#f2f4f7',
  error: '#fd680b',
};

export const typography = {
  fonts: {
    base: '"Metropolis", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  weight: {
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  height: {
    regular: 'normal',
  },
  size: {
    heading1: '24px',
    heading2: '20px',
    heading3: '16px',
    subHeadingL: '20px',
    subHeadingM: '16px',
    subHeadingS: '14px',
    breadL: '20px',
    breadM: '16px',
    breadS: '14px',
    button: '16px',
  },
};

export const breakpoints = {
  tablet: '(max-width: 768px)',
};

const isMobile = window.matchMedia(breakpoints.tablet).matches;

export const theme = {
  color,
  typography,
  breakpoints,
  isMobile,
};
