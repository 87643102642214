import React from 'react';
import styled from 'styled-components/macro';
import { mix, rgba } from 'polished';
import { RoundNextButton } from '../RoundNextButton/RoundNextButton';

export interface StepProps {
  title: string;
  description: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface TitleProps {
  description: string;
}

export const Step: React.FC<StepProps> = function (props) {
  const { title, description, onClick } = props;
  return (
    <StyledStep onClick={onClick}>
      <TextContainer>
        <Title description={description}>{title}</Title>
        {description && <Description>{description}</Description>}
      </TextContainer>

      <ButtonContainer>
        <RoundNextButton onClick={() => {}} />
      </ButtonContainer>
    </StyledStep>
  );
};

const StyledStep = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => mix(0.2, props.theme.color.accentColor, '#fff')};
  outline: none;
  box-shadow: ${rgba('#000', 0.08)} 0 2px 2px;
  border: none;
  padding: 10px 24px;
  width: 100%;
  display: flex;
  text-align: left;
  cursor: pointer;
`;

const TextContainer = styled.div``;

const ButtonContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: initial;
  }
`;

const Title = styled.h2<TitleProps>`
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.heading2};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  margin-bottom: ${(props) => props.description !== '' && '8px'};
  line-height: ${(props) => props.description === '' && '1.8'};
`;

const Description = styled.p`
  color: ${(props) => props.theme.color.textColor};
  font-size: ${(props) => props.theme.typography.size.subHeadingS};
`;
