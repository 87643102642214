import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconDone } from '../../assets/images/icon-done.svg';
import { AnswerTypes } from '../Answer/Answer';
import envConfig, { WidgetOrigin } from '../../constants';

type StyledCheckBoxProps = {
  isAquaOrigin: boolean;
  checked: boolean;
} & AnswerTypes;

export type CheckBoxProps = {
  checked: boolean;
  onChange: Function;
  testId: string;
} & AnswerTypes;

export const RadioButton: React.FC<CheckBoxProps> = function (props) {
  const {
    checked, onChange, styleType, testId,
  } = props;
  const { WIDGET_ORIGIN } = envConfig;
  const isAquaOrigin = WIDGET_ORIGIN === WidgetOrigin.aquadental;

  return (
    <StyledCheckBox isAquaOrigin={isAquaOrigin} checked={checked} styleType={styleType} data-testid={testId}>
      {checked && <IconDone />}
      <HiddenCheckBox checked={checked} onChange={(e) => onChange(e.target.checked)} />
    </StyledCheckBox>
  );
};

const StyledCheckBox = styled.label<StyledCheckBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.color.disabledColor};
  color: ${(props) => props.theme.color.background};
  cursor: pointer;

  ${(props) => css`
    .fill-color {
      fill: ${(props) => props.theme.color.textColor};
    }
  `};

  ${(props) => props.checked
    && css`
      background: ${(props) => props.theme.color.accentColor};
      border: 1px solid ${(props) => props.theme.color.accentColor};
    `}

  ${(props) => !props.isAquaOrigin
    && css`
      .fill-color {
        fill: ${(props) => props.theme.color.background};
      }
    `}

  ${(props) => props.styleType === 'square'
    && css`
      border-radius: 4px;
      width: 30px;
      height: 30px;
    `};
`;

const HiddenCheckBox = styled.input.attrs({ type: 'radio' })`
  display: none;
`;
