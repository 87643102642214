import React, { MouseEventHandler } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { ReactComponent as IconArrowForward } from '../../assets/images/icon-arrow-forward.svg';
import { theme } from '../../theme';
import { ButtonConfig, ButtonType, TypeID } from '../../types/Models';
import envConfig, { WidgetOrigin } from '../../constants';

export interface ButtonProps extends ButtonConfig {
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement> | null;
  id?: TypeID;
  testId?: string;
}

interface SpinnerContainerProps {
  readonly isActive: boolean | undefined;
}

interface IconProps {
  readonly url: string | undefined;
}

interface StyledButtonProps {
  buttonType: ButtonType
  isLoading: boolean | undefined
  isAquaOrigin: boolean
}

const { WIDGET_ORIGIN } = envConfig;
const isAquaOrigin = WIDGET_ORIGIN === WidgetOrigin.aquadental;

export const Button: React.FC<ButtonProps> = function (props) {
  const {
    id, children, icon, type, disabled, onClick, isLoading, buttonRef, testId,
  } = props;

  return (
    <StyledButton
      isAquaOrigin={isAquaOrigin}
      form={id}
      type="submit"
      ref={buttonRef}
      buttonType={type || 'default'}
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      data-testid={testId}
    >
      {type === 'icon' && <Icon url={icon} />}
      <ButtonText>{children}</ButtonText>
      {type === 'next' && <IconArrowForward width={24} height={24} fill={theme.color.background} />}
      <SpinnerContainer isActive={isLoading}>
        <Spinner />
      </SpinnerContainer>
    </StyledButton>
  );
};

Button.defaultProps = {
  disabled: false,
  isLoading: false,
  buttonRef: null,
  id: undefined,
  testId: 'submitButton',
};

const Icon = styled.div<IconProps>`
  width: 30px;
  height: 30px;
  background: url(${(props) => props.url}) no-repeat center/contain;
`;

const ButtonText = styled.span`
  margin: auto;
`;

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  padding: 0 32px;
  border-radius: ${(props) => (props.isAquaOrigin ? '100px' : '5px')};
  width: 100%;
  height: 56px;
  cursor: pointer;
  background: ${(props) => props.theme.color.accentColor};
  color: ${(props) => props.theme.color.black};
  font-weight: ${(props) => props.theme.typography.weight.bold};
  transition: all 0.2s ease;
  position: relative;
  margin-top: auto;
  span {
    text-transform: uppercase;
    font-size: 13px;
  }

  ${(props) => props.type
    && props.buttonType === 'next'
    && css`
      justify-content: space-between;
    `}

  ${(props) => props.theme.isMobile
    && props.buttonType === 'submit'
    && css`
      margin-top: 1rem;
    `}

  ${(props) => props.type
    && props.buttonType === 'icon'
    && css`
      justify-content: flex-start;

      span {
        flex: 1;
        text-align: center;
      }
    `}

  ${(props) => (props.disabled || props.isLoading)
    && css`
      pointer-events: none;
      background: ${(props) => props.theme.color.disabledColor};
      color: ${(props) => props.theme.color.background};
    `}
    
    ${(props) => props.buttonType === 'cancel'
    && css`
      background: transparent;
      border: 2px solid ${(props) => props.theme.color.textColor};
      color: ${(props) => props.theme.color.textColor};
      margin-bottom: 15px;
    `}
`;

const SpinnerContainer = styled.div<SpinnerContainerProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.color.accentColor};
  pointer-events: none;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease;

  ${(props) => props.isActive
    && css`
      opacity: 1;
    `}
`;

const spin = keyframes`
  to {
      transform: rotate(360deg);
    }
`;

const Spinner = styled.span`
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`;
