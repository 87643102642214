import { ApiObject, RudderAnalytics } from '@rudderstack/analytics-js';
import envConfig from '../constants';
import { IRudderAnalyticsService } from './interfaces/IRudderAnalyticsService';

class RudderAnalyticsService implements IRudderAnalyticsService {
  private rudderAnalytics: RudderAnalytics;

  constructor() {
    this.rudderAnalytics = new RudderAnalytics();
    this.rudderAnalytics.load(envConfig.RUDDERSTACK_API_KEY, envConfig.RUDDERSTACK_DATAPLANE_URL);
  }

  initialize() {
    this.rudderAnalytics.ready(() => {
      const anonymousId = this.rudderAnalytics.getAnonymousId();
      if (!anonymousId) return;

      this.rudderAnalytics.identify(anonymousId);
    });
  }

  getUserID() {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    return this.rudderAnalytics.getAnonymousId();
  }

  getRudderAnalyticsInstance() {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    return this.rudderAnalytics;
  }

  trackGBExperiments(data: ApiObject) {
    this.getRudderAnalyticsInstance().track('track-gb-experiment', data);
  }

  trackSentReview() {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    this.getRudderAnalyticsInstance().track('sent-review');
  }

  trackOpenAsWidget() {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    this.getRudderAnalyticsInstance().track('open-as-widget');
  }

  trackOpenBySMS() {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    this.getRudderAnalyticsInstance().track('open-by-sms');
  }

  trackOpenDirectly() {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    this.getRudderAnalyticsInstance().track('open-directly');
  }

  trackTransitToGoogleReview(googleReviewLink: string) {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    this.rudderAnalytics.track('transit-to-google-review', {
      link: googleReviewLink,
    });
  }

  trackTransitToTrustpilotReview(trustpilotReviewLink: string) {
    if (!this.rudderAnalytics) throw new Error('RudderAnalytics is not initialized');

    this.rudderAnalytics.track('transit-to-trustpilot-review', {
      link: trustpilotReviewLink,
    });
  }
}

export default new RudderAnalyticsService();
