import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Header } from '../Header/Header';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import { theme } from '../../theme';
import { ACCEPTED_LANGUAGES } from '../../service/interfaces/II18NService';

export interface WrapperProps {
  children: ReactElement | string;
}

export const Wrapper: React.FC<WrapperProps> = function (props) {
  const { children } = props;
  const showLangSwitcher = !theme.isMobile;

  return (
    <WrapperContainer>
      <Header />
      <ContentContainer>
        {children}
        {showLangSwitcher && <LanguageSwitcher languages={ACCEPTED_LANGUAGES} />}
      </ContentContainer>
    </WrapperContainer>
  );
};

const WrapperContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 1
`;
