import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as LangIcon } from '../../assets/images/lang-icon.svg';
import { AcceptedLanguagesTypes } from '../../service/interfaces/II18NService';
import I18NService from '../../service/I18NService';

interface LanguageSwitcherProps {
  languages: AcceptedLanguagesTypes;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = function (props) {
  const { languages } = props;
  const { currentLocale } = I18NService;
  const [activeLang, setActiveLang] = useState<string>(currentLocale);

  const handlerChangeLang = useCallback(
    (lang: string) => {
      setActiveLang(lang);
    },
    [activeLang],
  );

  useEffect(() => {
    I18NService.changeLanguage(activeLang);
  }, [activeLang]);

  return (
    <ContainerRow>
      <LangIcon />
      {Object.values(languages).map((lang, index) => (
        <SwitcherItem key={index} className={`${activeLang === lang && 'active'}`} onClick={() => handlerChangeLang(lang)}>
          {lang}
        </SwitcherItem>
      ))}
    </ContainerRow>
  );
};

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
`;

const SwitcherItem = styled.div`
  font-weight: bold;
  padding: 0 5px;
  font-size: ${(props) => props.theme.typography.size.heading2};
  cursor: pointer;
  line-height: 1;
  color: #909090;
  transition: 0.2s ease-in-out;
  position: relative;
  ${() => css`
    &:hover,
    &.active:after,
    &.active,
    &:hover:after {
      color: #000;
      opacity: 1;
    }

    &:after {
      content: '';
      display: block;
      position: relative;
      height: 1px;
      margin-top: -1px;
      opacity: 0;
      background-color: #000;
      bottom: 0;
      left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid #d2d2d2;
    }
  `};
`;
