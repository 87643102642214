import { ReactElement, useEffect } from 'react';
import RudderAnalyticsService from '../../service/RudderAnalyticsService';
import { sendingEventByFlow } from '../../helpers/sendingEventByFlow';

type RudderstackProviderProps = {
  children: ReactElement;
};

export const RudderstackProvider = (props: RudderstackProviderProps) => {
  const { children } = props;

  useEffect(() => {
    RudderAnalyticsService.initialize();
    sendingEventByFlow();
  }, []);

  return children;
};
