import { HTMLInputTypeAttribute } from 'react';

export enum ErrorExceptions {
  LOST_INTERNET_CONNECTION = 'LOST_INTERNET_CONNECTION',
  NO_SUCH_BOOKING = 'NO_SUCH_BOOKING',
  ALREADY_COMPLETED = 'ALREADY_COMPLETED',
  SUBMISSION_FAILED = 'SUBMISSION_FAILED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
}

export enum MessageType {
  UPDATE_CONFIG = 'UPDATE_CONFIG',
}

export interface ErrorHandling {
  title: string;
  message?: string;
  buttonText?: string;
}

export type Message<T> = {
  type: MessageType
  payload?: T
}

export type OverviewStepId = 'SELECTOR_CLINIC' | 'LEAVE_REVIEW' | 'USER_DETAILS' | 'SUCCESS'

export interface OverviewStep {
  id: OverviewStepId;
  title: string;
  description: string;
  state: 'active' | 'inactive' | 'done';
}

export interface Overview {
  title: string;
  description: string | string[];
  illustration: any;
  steps: OverviewStep[];
}

export interface OverviewData {
  overviews: Overview[];
}

export type ButtonType = 'icon' | 'submit' | 'next' | 'default' | 'cancel';

export interface ButtonConfig {
  children?: string | undefined;
  type?: ButtonType;
  icon?: string;
}

export type DataLabelType = keyof ReviewData;

export type AnswerTypes =
  | 'text'
  | 'check'
  | 'check_and_text'
  | 'check_more'
  | 'date'
  | 'check_and_date'
  | 'text-input'
  | 'radio'
  | 'rating';

export type DirectionAnswers = 'row' | 'column'

export interface ChildAnswer {
  label: string;
  value: number | string;
  selected: boolean;
  testId: string;
}

export interface AnswerData {
  id: number;
  dataLabel?: DataLabelType;
  inputOptions?: InputOptions;
  type: AnswerTypes;
  text: string;
  selected: boolean;
  inputTitle: string | undefined;
  inputValue?: string | undefined;
  directionAnswers?: DirectionAnswers;
  placeholder?: string | undefined;
  additional?: boolean;
  noSorting?: boolean;
  cancelsOthers?: boolean;
  disabledDays?: 'past';
  answers?: ChildAnswer[];
  testId: string;
}

export interface InputOptions {
  type: HTMLInputTypeAttribute;
  required?: boolean;
  testValue?: RegExp;
  mask?: string | Array<string | RegExp>;
  inputMode?: 'text' | 'none' | 'tel' | 'search' | 'email' | 'url' | 'numeric' | 'decimal';
  maskPlaceholder?: string;
  alwaysShowMask?: boolean;
  errorMessage?: string;
  test?: RegExp;
}

export type ScreenType = 'question' | 'selector' | 'authorization' | 'success' | 'payment' | 'confirmation';

export enum SubmitActionTypes {
  SEND_REVIEW_FOR_CLINIC = 'SEND_REVIEW_FOR_CLINIC',
  SEND_REVIEW_FOR_BOOKING = 'SEND_REVIEW_FOR_BOOKING',
  GET_CLINIC_LIST = 'GET_CLINIC_LIST'
}

export type TypeID = 'SELECTOR_CLINIC' | 'LEAVE_REVIEW' | 'USER_DETAILS' | 'SUCCESS'

export interface ConfigScreen {
  login?: boolean;
  noProgress?: boolean;
  confirmScreen?: {
    title: string;
    content: string;
  } | null;
  title: string;
  type: ScreenType;
  canSelectMultiple?: boolean;
  showIFrame?: boolean;
  formTitle?: string;
  formDescription?: string | string[] | null;
  formPrescription?: string | null;
  btnConfig?: ButtonConfig;
  submittable: boolean;
  submitAction?: keyof typeof SubmitActionTypes;
  id?: TypeID;
  data?: {
    answers: AnswerData[];
  };
}

export interface ConfigChapter {
  screens: ConfigScreen[];
}

export interface AppConfig {
  progress: number;
  currentChapter: number;
  currentScreen: number;
  chapters: ConfigChapter[];
}

export interface ReviewData {
  name: string;
  age: number;
  phone: string;
  email: string;
  gender: number;
  rating: number;
  comments: string;
}

export interface ReviewConfig {
  companyId: string;
  accentColor: string;
  textColor: string;
  disabledColor: string;
  backgroundColor: string;
}

export enum GrowthBookFeatures {
  GOOGLE_REVIEW_BUTTON = 'google-review-button',
  TRUSTPILOT_REVIEW_BUTTON = 'trustpilot-review-button',
  CONFIRM_REVIEW = 'confirm-review'
}

export interface ConfigFeatures {
  CONFIRM_MODAL_ON: GrowthBookFeatures | false;
  GOOGLE_REVIEW_BUTTON: GrowthBookFeatures | false;
  TRUSTPILOT_REVIEW_BUTTON: GrowthBookFeatures | false;
}
