import React from 'react';
import styled from 'styled-components/macro';

export interface StatusBarProps {
  progress: number;
}

export const StatusBar: React.FC<StatusBarProps> = function (props) {
  const { progress } = props;

  return <StyledProgressBar progress={progress} />;
};

const StyledProgressBar = styled.div<StatusBarProps>`
  background: ${(props) => {
    if (props.theme.isMobile) {
      return '#fff';
    }
    return props.theme.color.additional;
  }};
  height: 7px;
  width: 100%;
  border-radius: 90px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    border-radius: 90px;
    background: ${(props) => `${props.theme.color.accentColor}`};
    transition: width 0.2s ease;
  }
`;
