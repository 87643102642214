import {
  CreateReviewForClinicResponse,
  GetClinicResponse,
  GetReviewRequestInfoResponse,
  createReviewForClinic,
  createReviewForClinicByRequestId,
  getClinicById,
  getReviewRequestInfo,
} from 'patient-api-lib';
import {
  AnswerData, ConfigChapter, ConfigScreen, ErrorExceptions, ReviewData,
} from '../../types/Models';
import PhoneNumberService from '../PhoneNumberService';
import { IAPIReviewsDataService } from '../interfaces/IAPIReviewsDataService';
import RudderAnalyticsService from '../RudderAnalyticsService';

class APIReviewsDataService implements IAPIReviewsDataService {
  parseDataConfig(chapters: ConfigChapter[]): ReviewData {
    const reviewsScreens = chapters[0].screens;
    const reviewsData: any = {
      name: '',
      age: 0,
      phone: '',
      email: '',
      gender: 0,
      rating: 0,
      comments: '',
    };

    reviewsScreens.forEach((screen: ConfigScreen) => {
      if (!screen?.data) return;
      const { answers } = screen.data;

      if (!answers) return;
      answers.forEach((answer: AnswerData) => {
        if (!answer?.dataLabel) return;
        if (answer.inputValue !== '') {
          reviewsData[answer.dataLabel] = answer.inputValue;
        } else {
          reviewsData[answer.dataLabel] = answer.selected;
        }
      });
    });

    return { ...reviewsData };
  }

  async sendReviewsByClinic(chapters: ConfigChapter[], setErrorCode: Function, clinicId: string): Promise<CreateReviewForClinicResponse> {
    const {
      email, phone, comments, name, gender, age, rating,
    } = this.parseDataConfig(chapters);

    const formatPhoneNumber = PhoneNumberService.getOriginalFormat(phone);

    return createReviewForClinic(clinicId, email, formatPhoneNumber, comments, name, gender, age, rating, rating, rating)
      .then((response) => {
        RudderAnalyticsService.trackSentReview();
        return response;
      })
      .catch((err) => {
        setTimeout(() => {
          setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
        }, 500);
        throw new Error(err);
      });
  }

  async sendReviewsByBooking(chapters: ConfigChapter[], setErrorCode: Function, reviewID: string): Promise<CreateReviewForClinicResponse> {
    const { comments, rating } = this.parseDataConfig(chapters);

    return createReviewForClinicByRequestId(reviewID, comments, rating)
      .then((response) => {
        RudderAnalyticsService.trackSentReview();
        return response;
      })
      .catch((err) => {
        setTimeout(() => {
          setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
        }, 500);
        throw new Error(err);
      });
  }

  async getReviewInfo(reviewId: string, setErrorCode: Function): Promise<GetReviewRequestInfoResponse> {
    const response = await getReviewRequestInfo(reviewId).catch((err) => {
      setTimeout(() => {
        setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
      }, 500);
      throw new Error(err);
    });

    return response;
  }

  async getReviewClinicInfo(clinicId: string, setErrorCode: Function): Promise<GetClinicResponse> {
    const response = await getClinicById(clinicId).catch((err) => {
      setTimeout(() => {
        setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
      }, 500);
      throw new Error(err);
    });

    return response;
  }
}

export default new APIReviewsDataService();
