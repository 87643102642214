import { getReviewIDFromURL } from './getReviewIDFromURL';
import APIReviewsDataService from '../service/API/APIReviewsDataService';
import { ErrorExceptions } from '../types/Models';

const [reviewID] = getReviewIDFromURL();

export const handlerReviewStatus = async (setErrorCode: Function, setScreenLoader: Function) => {
  if (!reviewID) throw new Error('Review ID is not found');

  const reviewInfo = await APIReviewsDataService.getReviewInfo(reviewID, setErrorCode);
  const clinicId = reviewInfo.getReviewRequest()?.getClinicId();
  const bookingHasReview = reviewInfo.getReviewRequest()?.getHasReview();

  if (!clinicId) {
    setTimeout(() => {
      setErrorCode(ErrorExceptions.SUBMISSION_FAILED);
    }, 500);
    return { reviewInfo: reviewInfo.toObject(), clinicInfo: null };
  }

  if (bookingHasReview) {
    setTimeout(() => {
      setErrorCode(ErrorExceptions.ALREADY_COMPLETED);
    }, 500);
    return { reviewInfo: reviewInfo.toObject(), clinicInfo: null };
  }

  const clinicInfo = await APIReviewsDataService.getReviewClinicInfo(clinicId, setErrorCode).finally(() => {
    setTimeout(() => {
      setScreenLoader(false);
    }, 500);
  });

  return { reviewInfo: reviewInfo.toObject(), clinicInfo: clinicInfo.toObject() };
};
