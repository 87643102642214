import React, {
  MouseEventHandler, useCallback, useContext, useState,
} from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash.debounce';
import { Screen } from '../../components/Screen/Screen';
import { Form } from '../../components/Form/Form';
import { ErrorScreen } from '../ErrorScreen';
import { ConfigContext } from '../../config';
import { ConfigScreen } from '../../types/Models';
import { TextInput } from '../../components/TextInput/TextInput';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import { SelectRow } from './SelectRow';
import { theme } from '../../theme';
import { AquaCityClinics, aquaClinics } from '../../data/aqua-clinics';
import { LANGUAGE } from '../../service/interfaces/II18NService';

export interface ScreenConfig extends ConfigScreen {
  progress: number;
  isLoading: boolean;
}

export interface SelectorScreenProps {
  onSubmit: Function;
  onDataChange: Function;
  screenIndex: number;
  screensLength: number;
  onBackClick: MouseEventHandler<HTMLButtonElement>;
  screenConfig: ScreenConfig;
}

export const SelectorScreen: React.FC<SelectorScreenProps> = function (props) {
  const {
    onSubmit, onDataChange, screenConfig, screenIndex, screensLength, onBackClick,
  } = props;

  const {
    id, progress, type, btnConfig, formTitle, formDescription, submittable, submitAction, isLoading, noProgress,
  } = screenConfig;
  const [searchValue, setSearchValue] = useState<string>('');
  const [clinicsData, setClinicsData] = useState<AquaCityClinics[]>(aquaClinics);

  const updateSearchValue = useCallback(
    debounce((str) => {
      if (str !== '') {
        const data = [...clinicsData];
        const filteredCities = data.filter((el) => el.clinics
          .some((val) => val.clinic.toLowerCase().indexOf(str.toLowerCase()) !== -1));
        // eslint-disable-next-line no-return-assign, no-param-reassign
        filteredCities.map((item) => item.active = true);
        const filteredData = filteredCities.map((item) => ({
          ...item,
          clinics: item.clinics.filter((el) => el.clinic
            .toLowerCase().indexOf(str.toLowerCase()) !== -1),
        }));

        setClinicsData(filteredData);
      } else {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        aquaClinics.forEach((item) => item.active = false);
        setClinicsData(aquaClinics);
      }
    }, 500),
    [],
  );

  const sortingClinics = useCallback(
    (clinics: AquaCityClinics[]) => clinics
      .sort((a, b) => a.city.localeCompare(b.city, LANGUAGE.SV)),
    [clinicsData],
  );

  const handlerChangeSearch = (value: string) => {
    updateSearchValue(value);
    setSearchValue(value);
  };

  const handlerChooseClinic = (id: string) => {
    onDataChange([{
      inputValue: id,
      dataLabel: 'clinicId',
    }]);

    onSubmit(submitAction);
  };

  const handlerExpandRow = (id: string) => {
    const data = [...clinicsData];
    const expandItem = clinicsData.findIndex((item) => item.id === id);
    data[expandItem].active = !data[expandItem].active;

    setClinicsData(data);
  };

  const { errorObject } = useContext(ConfigContext);

  return errorObject ? (
    <ErrorScreen errorObject={errorObject} />
  ) : (
    <Screen
      progress={progress}
      type={type}
      btnConfig={{
        ...btnConfig,
        onClick: submittable ? () => onSubmit(submitAction) : () => {},
        disabled: !submittable,
        isLoading,
      }}
      noProgress={noProgress}
      screenIndex={screenIndex}
      screensLength={screensLength}
      onBackClick={onBackClick}
      hideButton
    >
      <Form type={type} title={formTitle} description="" id={id}>
        <SelectorForm>
          <InputRow>
            <TextInput
              title=""
              inputOptions={{ type: 'search' }}
              onBlur={() => {}}
              onFocus={() => {}}
              placeholder={typeof formDescription === 'string' ? formDescription : ''}
              value={searchValue}
              onChange={handlerChangeSearch}
              showError={false}
              testId="searchInput"
            />
            {searchValue.length === 0 && (
            <SearchIcon
              className="search-icon"
              fill={theme.color.disabledColor}
              width={16}
              height={16}
            />
            )}
          </InputRow>
          <SelectList data-testid="clinicsList">
            {sortingClinics(clinicsData).map((data) => (
              <SelectRow
                data={data}
                key={data.id}
                onClickRow={handlerChooseClinic}
                onClickTitleRow={handlerExpandRow}
              />
            ))}
          </SelectList>
        </SelectorForm>
      </Form>
    </Screen>
  );
};

const SelectorForm = styled.div``;

const InputRow = styled.div`
  display: flex;
  position: relative;
  ${() => css`
    & .search-icon {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  `}
`;

const SelectList = styled.div`
  padding: 15px 0;
`;
