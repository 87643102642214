import { TFunctionKeys, TOptions } from 'i18next';

export enum LANGUAGE {
  SV = 'sv',
  EN = 'en'
}

export const DEFAULT_LANG = LANGUAGE.SV;

export const ACCEPTED_LANGUAGES: { [key: string]: string } = {
  EN: LANGUAGE.EN,
  SV: LANGUAGE.SV,
};

export type AcceptedLanguagesTypes = typeof ACCEPTED_LANGUAGES;

export interface II18NService {
  changeLanguage(lang: string): Promise<void>
  onLoadedLanguage(callback: () => void): void
  onLanguageChanged(callback: (language: string) => void): void
  offLoadedLanguage(callback: () => void): void
  offLanguageChanged(callback: (language: string) => void): void
  getCurrentLanguage(): Promise<string>
  getTranslation(keys: TFunctionKeys | TFunctionKeys[], options?: TOptions): string
}
