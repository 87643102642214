import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import I18NService from '../../service/I18NService';
import envConfig from '../../constants';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';

interface LanguageProviderProps {
  children: ReactElement;
}

const LanguageProvider: React.FC<LanguageProviderProps> = function (props) {
  const { children } = props;
  const [isReady, setIsReady] = useState(false);
  const { currentLocale } = I18NService;
  const { WIDGET_ORIGIN } = envConfig;

  useEffect(() => {
    I18NService.init().then(() => setIsReady(true));
  }, [currentLocale]);

  const title = useMemo(
    () => `${capitalizeFirstLetter(WIDGET_ORIGIN)} - ${currentLocale === 'en' ? 'Review' : 'Omdöme'}`,
    [WIDGET_ORIGIN, currentLocale],
  );

  return isReady ? (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      {children}
    </>
  ) : null;
};

export default LanguageProvider;
